import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import emailSend from "../../../assets/email_sent.webp";
import { backend_api } from "../../../static/server";

const NewUserPage = () => {
  const [searchParams] = useSearchParams();
  const urlMail = searchParams.get("mail");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openForgotPass, setOpenForgotPass] = useState(true);

  useEffect(() => {
    if (urlMail) {
      handleForgotPasswordLink(urlMail);
    }
  }, [urlMail]);

  const handleForgotPasswordLink = async (email) => {
    try {
      const data = { email: email };
      const response = await axios.get(
        `${backend_api}user/forget-password/?send_reset_link&email=${email}`,
        data
      );
      console.log("Forgot Password Response:", response);
      setIsEmailSent(true);
    } catch (error) {
      console.error("Forgot Password Error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseForgotPass = () => {
    setOpenForgotPass(false);
  };

  return (
    <Dialog
      open={openForgotPass}
      onClose={handleCloseForgotPass}
      maxWidth="sm"
      fullWidth
      PaperProps={{ elevation: 3, sx: { borderRadius: 2, p: 2 } }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        {!isEmailSent ? "Reset Password" : null}
        <IconButton
          aria-label="close"
          onClick={handleCloseForgotPass}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" py={3}>
            <CircularProgress />
          </Box>
        ) : isEmailSent ? (
          <Box sx={{ textAlign: "center", py: 3 }}>
            <Box sx={{ height: "10rem" }}>
              <img
                src={emailSend}
                alt="Email Sent"
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </Box>
            <Typography
              variant="body1"
              color="primary.main"
              sx={{ pb: 2, fontSize: "1.2rem", fontWeight: "500" }}
            >
              Check your email
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontSize: "0.9rem" }}
            >
              We've sent a password reset link to your email address. Please
              check your inbox and follow the instructions to reset your
              password.
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ fontSize: "0.8rem" }}
            >
              {urlMail}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1" color="error" textAlign="center">
            Failed to send reset link. Please try again.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewUserPage;
