import React from "react";
import { Box, Typography, styled } from "@mui/material";

const StyledSvg = styled("svg")({
  transform: "rotate(-90deg)", // Rotates the SVG for the progress bar
  width: "100%",
  height: "100%",
});

const ProfileCircularProgress = ({ value }) => {
  const radius = 16;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  return (
    <Box
      sx={{
        position: "relative",
        width: 50,
        height: 50,
      }}
    >
      {/* SVG container */}
      <StyledSvg viewBox="0 0 40 40">
        {/* Background circle */}
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="#E5E7EB" // Light gray background
          strokeWidth="3"
        />
        {/* Progress circle */}
        <circle
          cx="20"
          cy="20"
          r={radius}
          fill="none"
          stroke="#00B3B0"
          strokeWidth="3"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </StyledSvg>

      {/* Percentage text */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "0.7rem",
            fontWeight: "600",
            color: "#00B3B0", // Blue text color
          }}
        >
          {value}%
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileCircularProgress;
