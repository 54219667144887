import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles, { pageScrollStyle } from "../../../styles/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import DataTable from "../../DataTable";
import moneytranferImg from "../../../assets/money-tranfer.webp";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { CloseRounded } from "@mui/icons-material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { getAllFilterContacts } from "../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import { backend_api } from "../../../static/server";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import { currencyList } from "../../../static/staticdata";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      p: "2rem",
      gap: "1rem",
    }}
  >
    <Box sx={{ height: "9rem" }}>
      <img
        src={moneytranferImg}
        alt="Company Logo"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        onError={(e) => {
          e.onerror = null;
          e.target.src = require("../../../assets/no-preview.webp");
        }}
      />
    </Box>
    <Typography variant="body1">No Bank Account available.</Typography>
  </Box>
);
const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-end px-2 py-2">
      <div className="flex gap-2">
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );
};

const renderContactOption = (props, option) => (
  <li
    {...props}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography variant="body1">
      {option?.first_name
        ? `${option?.first_name} ${option?.last_name}`
        : option?.company_name
        ? option?.company_name
        : ""}
    </Typography>
    <Typography variant="body1">
      {option?.contact_type === "person" ? (
        <AccountCircleRoundedIcon
          sx={{ color: "#bf3fe1", fontSize: "0.9rem" }}
        />
      ) : (
        <BusinessRoundedIcon sx={{ color: "#2e77f5", fontSize: "0.9rem" }} />
      )}
    </Typography>
  </li>
);

const BankTransaction = () => {
  const dispatch = useDispatch();
  const [anchorDetail, setAnchorDetail] = useState(null);
  const { account_name } = useParams();
  const decodedBankName = account_name.replace(/-/g, " "); // Convert '-' back to space
  const bankDetail = JSON.parse(localStorage.getItem("AllAccounts"))[0];
  const [openNewTransForm, setOpenNewTransForm] = useState(false);
  const [openTranferFund, setOpenTranferFund] = useState(false);
  const { token } = useSelector((data) => data.user);
  const { filteredContacts } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(
      getAllFilterContacts({
        url: `${backend_api}contact/retrieve/`,
        token: token,
      })
    );
  }, [dispatch, token]);

  console.log(filteredContacts?.data);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      payment_amount: "",
    },
  });

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      headerAlign: "left",
      renderCell: (item) => (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "0.2rem ",
              width: "100%",
            }}
          >
            <Tooltip title="Edit">
              <IconButton color="primary" size="small">
                <DriveFileRenameOutlineRoundedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                size="small"
                aria-label="more"
                // onClick={() => handleDeleteBank(item.id)}
              >
                <DeleteRoundedIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    { field: "source", headerName: "Source/Destination", width: 200 },
    {
      field: "reference",
      headerName: "Reference",
      width: 150,
    },
    { field: "payer/payee", headerName: "Payer/Payee", width: 120 },
    { field: "client", headerName: "Client", width: 120 },
    { field: "matter", headerName: "Matter", width: 120 },
    { field: "fund_out", headerName: "Fund out", width: 120 },
    { field: "fund_in", headerName: "Fund In", width: 120 },
    {
      field: "running_balance",
      headerName: "Running Balance",
      minWidth: 200,
      headerAlign: "right",
      renderCell: (item) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {`${item.value} ${item.row.currency}`}
          </Typography>
        </Box>
      ),
    },
  ];
  const rows = [];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="flex justify-between bg-white rounded-lg items-center shadow-md p-2">
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <h2 className={`${styles.title}`}>
                Transactions of {decodedBankName}
              </h2>
              <IconButton onClick={(e) => setAnchorDetail(e.currentTarget)}>
                <KeyboardArrowDownRoundedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorDetail}
                open={Boolean(anchorDetail)}
                onClose={() => setAnchorDetail(null)}
              >
                <Box
                  sx={{
                    width: "30rem",
                    px: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" fontWeight="600">
                      Bank Details
                    </Typography>
                    {bankDetail?.default_account ? (
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "center",
                          fontSize: "0.8rem",
                          width: "4rem",
                          fontWeight: 500,
                          px: "0.2rem",
                          borderRadius: "1rem",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid",
                          borderColor: bankDetail?.default_account
                            ? "#4CAF50"
                            : "none",
                          color: bankDetail?.default_account
                            ? "#4CAF50"
                            : "none",
                        }}
                      >
                        {bankDetail?.default_account ? "default" : ""}
                      </Typography>
                    ) : null}
                  </Box>

                  <Divider />

                  {Object.entries(bankDetail).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.9rem",
                          fontWeight: 500,
                          color: "text.secondary",
                          textTransform: "capitalize",
                        }}
                      >
                        {key.replace(/_/g, " ")}
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "0.9rem" }}>
                        {typeof value === "boolean"
                          ? value
                            ? "Yes"
                            : "No"
                          : value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Menu>
            </Box>
            <Box className="flex gap-4">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpenTranferFund(true)}
              >
                Transfer Funds
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenNewTransForm(true)}
              >
                New Transaction
              </Button>
            </Box>
          </div>
          <div className="flex items-start justify-between w-full">
            <div className="w-full">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box
                  className="bg-white rounded-lg justify-between h-[80vh] p-2 w-full shadow-lg"
                  sx={{ ...pageScrollStyle, overflow: "auto" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "0.8rem",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box>
                          <TextField
                            size="small"
                            placeholder="Search task"
                            className="flex-1"
                            label="Search"
                            sx={{ width: "15rem" }}
                          />
                        </Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<FilterListRoundedIcon />}
                        >
                          Filter
                        </Button>
                      </Box>
                    </Box>

                    <Box>
                      <DataTable
                        columns={columns}
                        rows={rows}
                        CustomToolbar={CustomToolbar}
                        CustomNoRowsOverlay={CustomNoRowsOverlay}
                        // loading={loading}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Box>

        {/* -------------------------- NEW TRANSACTION FORM HERE -------------------------- */}
        <Dialog
          open={openNewTransForm}
          TransitionComponent={Transition}
          onClose={() => setOpenNewTransForm(false)}
          PaperProps={{
            style: {
              padding: 0,
              width: "40rem",
            },
          }}
          sx={{
            "& .MuiDialog-paper": {
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "1rem",
              py: "0.5rem",
              bgcolor: "primary.main",
              position: "sticky",
              top: "0rem",
              left: "0rem",
              zIndex: 9,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                variant="body1"
                color="white.main"
                sx={{ fontSize: "1rem" }}
              >
                New transaction
              </Typography>
            </Box>
            <IconButton
              edge="start"
              color="white"
              onClick={() => setOpenNewTransForm(false)}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%", p: "1rem" }}>
            <form>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth>
                        <Autocomplete
                          size="small"
                          fullWidth
                          options={currencyList || []}
                          getOptionLabel={(option) => option?.label || ""}
                          value={
                            currencyList?.find(
                              (option) => option?.value === field?.value
                            ) || null
                          }
                          onChange={(_, newValue) =>
                            field.onChange(newValue ? newValue?.value : null)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.value === value?.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Currency"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="payment_amount"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Amount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "Date is required" }}
                    render={({ field, value }) => (
                      <DatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          const isValidDate =
                            newValue && dayjs(newValue).isValid();
                          field.onChange(
                            isValidDate
                              ? dayjs(newValue)
                                  .startOf("day")
                                  .format("YYYY-MM-DD")
                              : null
                          );
                        }}
                        sx={{
                          width: "100%",
                        }}
                        slotProps={{
                          textField: {
                            label: "Date",
                            size: "small",
                            variant: "outlined",
                            fullWidth: true,
                            required: true,
                          },
                          popper: {
                            sx: {
                              "& .MuiPickersPopper-root": {
                                maxWidth: "100%",
                                maxHeight: "100%",
                                overflow: "hidden",
                              },
                              "& .MuiPaper-root": {
                                width: "fit-content",
                                maxHeight: "15rem",
                                overflowY: "auto",
                                ...pageScrollStyle,
                              },
                            },
                          },
                        }}
                        format="MM/DD/YYYY"
                      />
                    )}
                  />
                  <Controller
                    // in this field bank accound come with api like all bank accound api and it auto selet only opreating bank account type when that payment not relate to truest accound if it relate trust accound then only show truest bank accound
                    name="account"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          options={
                            [
                              {
                                label: "Bank Name(opreating bank)",
                                value: "bank_id",
                              },
                            ] || []
                          }
                          renderInput={(params) => (
                            <TextField
                              label="Account"
                              {...params}
                              size="small"
                              required
                              error={!!errors?.account}
                              helperText={errors?.account?.message}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Controller
                  name="client"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        options={filteredContacts?.data || []}
                        getOptionLabel={(option) =>
                          option?.first_name
                            ? `${option?.first_name} ${option?.last_name}`
                            : option?.company_name || ""
                        }
                        value={field.value || null} // Single selection
                        onChange={(_, newValue) => field.onChange(newValue)}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        noOptionsText="No contact found"
                        renderOption={renderContactOption}
                        renderInput={(params) => {
                          const { InputProps, ...rest } = params;
                          return (
                            <TextField
                              {...rest}
                              label="Client"
                              size="small"
                              InputProps={{
                                ...InputProps,
                                startAdornment: field.value ? (
                                  <Chip
                                    size="small"
                                    key={field.value.id}
                                    sx={{
                                      bgcolor:
                                        field.value?.contact_type === "person"
                                          ? "#bf3fe1"
                                          : "#2e77f5",
                                      color: "#fff",
                                    }}
                                    label={
                                      <Typography variant="body1">
                                        {field.value?.contact_type ===
                                        "person" ? (
                                          <AccountCircleRoundedIcon
                                            sx={{
                                              color: "#fff",
                                              fontSize: "0.9rem",
                                            }}
                                          />
                                        ) : (
                                          <BusinessRoundedIcon
                                            sx={{
                                              color: "#fff",
                                              fontSize: "0.9rem",
                                            }}
                                          />
                                        )}
                                      </Typography>
                                    }
                                  />
                                ) : null,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="matter"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        options={
                          [
                            {
                              label: "00001-sajan",
                              value: "1",
                            },
                          ] || []
                        }
                        renderInput={(params) => (
                          <TextField
                            label="Matter"
                            {...params}
                            size="small"
                            required
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="source"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Source/Destination"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="payer/payee"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Payer/Payee"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="exchange_rate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Exchange Rate"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Type"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={1.5}
                      fullWidth
                      label="Description"
                    />
                  )}
                />
              </Box>
            </form>
          </Box>
          <DialogActions
            sx={{
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: "0rem",
              left: "0rem",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button variant="outlined" color="primary">
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenNewTransForm(true)}
            >
              Record transaction
            </Button>
          </DialogActions>
        </Dialog>
        {/* ----------------------------- TRANFER FUNDS FORM HERE -------------------------- */}
        <Dialog
          open={openTranferFund}
          TransitionComponent={Transition}
          onClose={() => setOpenTranferFund(false)}
          PaperProps={{
            style: {
              padding: 0,
              width: "40rem",
            },
          }}
          sx={{
            "& .MuiDialog-paper": {
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "1rem",
              py: "0.5rem",
              bgcolor: "primary.main",
              position: "sticky",
              top: "0rem",
              left: "0rem",
              zIndex: 9,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                variant="body1"
                color="white.main"
                sx={{ fontSize: "1rem" }}
              >
                New transaction
              </Typography>
            </Box>
            <IconButton
              edge="start"
              color="white"
              onClick={() => setOpenTranferFund(false)}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
          </Box>
          <Box sx={{ p: "1rem" }}>
            <form>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="between_to"
                    control={control}
                    rules={{ required: " is required" }}
                    render={({ field }) => (
                      <FormControl
                        size="small"
                        fullWidth
                        error={!!errors?.between_to}
                        required
                      >
                        <InputLabel
                          id="between_to-label"
                          sx={{ bgcolor: "#fff", px: "0.3rem" }}
                        >
                          Between to
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="between_to-label"
                          defaultValue="bank_ac"
                        >
                          <MenuItem value={"bank_ac"}>Bank Accounts</MenuItem>
                          <MenuItem value={"matters"}>Matters</MenuItem>
                        </Select>
                        {errors?.between_to && (
                          <FormHelperText>
                            {errors.between_to.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="client"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          options={filteredContacts?.data || []}
                          getOptionLabel={(option) =>
                            option?.first_name
                              ? `${option?.first_name} ${option?.last_name}`
                              : option?.company_name || ""
                          }
                          value={field.value || null} // Single selection
                          onChange={(_, newValue) => field.onChange(newValue)}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id
                          }
                          noOptionsText="No contact found"
                          renderOption={renderContactOption}
                          renderInput={(params) => {
                            const { InputProps, ...rest } = params;
                            return (
                              <TextField
                                {...rest}
                                label="Client"
                                size="small"
                                required
                                InputProps={{
                                  ...InputProps,
                                  startAdornment: field.value ? (
                                    <Chip
                                      size="small"
                                      key={field.value.id}
                                      sx={{
                                        bgcolor:
                                          field.value?.contact_type === "person"
                                            ? "#bf3fe1"
                                            : "#2e77f5",
                                        color: "#fff",
                                      }}
                                      label={
                                        <Typography variant="body1">
                                          {field.value?.contact_type ===
                                          "person" ? (
                                            <AccountCircleRoundedIcon
                                              sx={{
                                                color: "#fff",
                                                fontSize: "0.9rem",
                                              }}
                                            />
                                          ) : (
                                            <BusinessRoundedIcon
                                              sx={{
                                                color: "#fff",
                                                fontSize: "0.9rem",
                                              }}
                                            />
                                          )}
                                        </Typography>
                                      }
                                    />
                                  ) : null,
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Box>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "Date is required" }}
                    render={({ field, value }) => (
                      <DatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          const isValidDate =
                            newValue && dayjs(newValue).isValid();
                          field.onChange(
                            isValidDate
                              ? dayjs(newValue)
                                  .startOf("day")
                                  .format("YYYY-MM-DD")
                              : null
                          );
                        }}
                        sx={{
                          width: "100%",
                        }}
                        slotProps={{
                          textField: {
                            label: "Date",
                            size: "small",
                            variant: "outlined",
                            fullWidth: true,
                            required: true,
                          },
                          popper: {
                            sx: {
                              "& .MuiPickersPopper-root": {
                                maxWidth: "100%",
                                maxHeight: "100%",
                                overflow: "hidden",
                              },
                              "& .MuiPaper-root": {
                                width: "fit-content",
                                maxHeight: "15rem",
                                overflowY: "auto",
                                ...pageScrollStyle,
                              },
                            },
                          },
                        }}
                        format="MM/DD/YYYY"
                      />
                    )}
                  />
                </Box>

                <Controller
                  name="matter"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        options={
                          [
                            {
                              label: "00001-sajan",
                              value: "1",
                            },
                          ] || []
                        }
                        renderInput={(params) => (
                          <TextField label="Matter" {...params} size="small" />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="source"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          options={
                            [
                              {
                                label: "00001-sajan",
                                value: "1",
                              },
                            ] || []
                          }
                          renderInput={(params) => (
                            <TextField
                              label="Source"
                              {...params}
                              size="small"
                              required
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="destination"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          options={
                            [
                              {
                                label: "00001-sajan",
                                value: "1",
                              },
                            ] || []
                          }
                          renderInput={(params) => (
                            <TextField
                              label="Destination"
                              {...params}
                              size="small"
                              required
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <FormControl size="small" fullWidth>
                        <Autocomplete
                          size="small"
                          fullWidth
                          options={currencyList || []}
                          getOptionLabel={(option) => option?.label || ""}
                          value={
                            currencyList?.find(
                              (option) => option?.value === field?.value
                            ) || null
                          }
                          onChange={(_, newValue) =>
                            field.onChange(newValue ? newValue?.value : null)
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.value === value?.value
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Currency"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="payment_amount"
                    control={control}
                    rules={{ required: "This field is required" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Amount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoneyRoundedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="payer/payee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Payer/Payee"
                      size="small"
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={1.5}
                      fullWidth
                      label="Description"
                    />
                  )}
                />
              </Box>
            </form>
          </Box>
          <DialogActions
            sx={{
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: "0rem",
              left: "0rem",
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Button variant="outlined" color="primary">
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenNewTransForm(true)}
            >
              Record Transfer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default BankTransaction;
