import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllAccounts = createAsyncThunk(
  "accounts/getAllAccounts",
  async ({ url, body, token }) => {
    try {
      const response = await axios.get(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to get custom field");
      throw error.response?.data || error.message;
    }
  }
);

const AccountSlice = createSlice({
  name: "account",
  initialState: {
    AllAccounts: null, // All accounts data
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.AllAccounts = action?.payload;
      })
      .addCase(getAllAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action?.error?.message || "Account is not there Create one ";
      });
  },
});

export const AccountReducer = AccountSlice.reducer;
