import React from "react";
import { Avatar } from "@mui/material";
import { backend_url } from "../static/server";

const getRandomBgColor = () => {
  const colors = ["#F87171", "#60A5FA", "#34D399", "#FBBF24", "#A78BFA"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const UserAvatar = ({ userData, height, width }) => {
  const randomColor = getRandomBgColor();

  return (
    <Avatar
      alt={userData?.first_name || "User"}
      src={userData?.avatar ? `${backend_url}${userData.avatar}` : ""}
      sx={{
        width: height,
        height: height,
        backgroundColor: userData?.avatar ? "transparent" : "#F87171",
        color: "white",
      }}
    >
      {!userData?.avatar && userData?.first_name?.[0]}
    </Avatar>
  );
};

export default UserAvatar;
