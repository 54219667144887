import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  InputBase,
  Paper,
  Drawer,
  Avatar,
} from "@mui/material";
import { FaSignOutAlt } from "react-icons/fa";
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useNavigate } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { logout } from "../../../redux/Apps/users/userSlice";
import { companyLogo, profileCompleted } from "../../../static/staticdata";
import UserAvatar from "../../UserAvatar";
import { CloseRounded, PersonRounded } from "@mui/icons-material";
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import ProfileCircularProgress from "../../ProfileCircularProgress";

const Navbar = ({ handleDrawerOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false)
  const [searchBox, setSearchBox] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState(null);
  const [notifyDrawer, setNotifyDrawer] = useState(false)
  const profileNotify = localStorage.getItem("profileNotify")
  const open = Boolean(anchorEl);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));

  const allFields = [
    "first_name",
    "last_name",
    "birth_date",
    "gender",
    "mobile",
    "address_1",
    "city",
    "state",
    "country",
    "pincode",
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleNotifyDrawer = (newOpen) => () => {
    setNotifyDrawer(newOpen);
  };

  const items = ['Apple', 'Banana', 'Orange', 'Mango', 'Grapes', 'Pineapple'];

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim()) {
      setFilteredItems(
        items.filter((item) =>
          item.toLowerCase().includes(term.toLowerCase())
        )
      );
      setSearching(true);
    } else {
      setSearching(false);
      setFilteredItems([]);
    }
  };


  const handleLogout = () => {
    navigate("/login");
    handleClose();
    dispatch(logout());
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root")
  }



  useEffect(() => {
    if (storedUserData) setUserData(storedUserData)

  }, []);

  useEffect(() => {
    if (storedUserData) {
      const hasEmptyFields = allFields.some((field) => {
        const value = storedUserData[field];
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          (field === "birth_date" && !value) ||
          (typeof value === "string" && value.trim() === "")
        );
      });

      if (hasEmptyFields) {
        const filledFields = allFields.filter((field) => {
          const value = storedUserData[field];
          return (
            value !== undefined &&
            value !== null &&
            value !== "" &&
            (field !== "birth_date" || value) &&
            (typeof value !== "string" || value.trim() !== "")
          );
        });

        const progress = Math.round(
          (filledFields.length / allFields.length) * 50
        );

        localStorage.setItem("profileNotify", "true");
        localStorage.setItem("profileCompleted", progress.toString());
      }
    }
  }, [storedUserData]);


  return (
    <Box sx={{ width: '100%', bgcolor: "primary.main", p: "0.5rem 1rem" }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '2rem' }}>
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                filter: "brightness(0) invert(1)"
              }}
              onError={(e) => {
                e.onerror = null;
                e.target.src = require("../../../assets/nav-lexmom.webp");
              }}
            />
          </Box>
        </Box>


        <Box sx={{ display: 'flex', alignItems: 'center', gap: "0.5rem", position: "relative" }}>
          <Box sx={{ position: 'relative', }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#fff',
                borderRadius: '2rem',
                px: '0.5rem',
                py: '0.3rem',
                border: '1px solid #ccc',
                width: searchBox ? "15rem" : "2.6rem",
                transition: "all 0.2s"
              }}
            >
              <InputBase
                fullWidth
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <SearchRoundedIcon sx={{ cursor: 'pointer', }} onClick={() => setSearchBox(!searchBox)} />
            </Box>

            {/* Dropdown / Dialog Box */}
            {searching && (
              <Paper
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  mt: 1,
                  maxHeight: 200,
                  overflowY: 'auto',
                  borderRadius: 2,
                  boxShadow: 3, zIndex: 9
                }}
              >
                {filteredItems.length > 0 ? (
                  filteredItems.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        px: 2,
                        py: 1,
                        cursor: 'pointer',
                        '&:hover': { bgcolor: '#f5f5f5' },
                      }}
                      onClick={() => {
                        setSearchTerm(item);
                        setSearching(false);
                      }}
                    >
                      <Typography>{item}</Typography>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ px: 2, py: 1 }}>
                    <Typography color="text.secondary">No results found</Typography>
                  </Box>
                )}
              </Paper>
            )}
          </Box>
          {/* Notification Bell */}
          <Tooltip title="Notifications">
            <Box sx={{ position: "relative" }}>
              <IconButton
                color="white"
                onClick={toggleNotifyDrawer(true)}
                sx={{
                  animation: profileNotify ? "shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both" : "none",
                  "@keyframes shake": {
                    "10%, 90%": { transform: "translate3d(-1px, 0, 0)" },
                    "20%, 80%": { transform: "translate3d(2px, 0, 0)" },
                    "30%, 50%, 70%": { transform: "translate3d(-4px, 0, 0)" },
                    "40%, 60%": { transform: "translate3d(4px, 0, 0)" },
                  },
                }}
              >
                <NotificationsActiveRoundedIcon sx={{ fontSize: "1.6rem" }} />
              </IconButton>
              {profileNotify && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                    width: "10px",
                    height: "10px",
                    bgcolor: "red",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton color="white">
              <SettingsRoundedIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              sx={{ ml: 2, }}

            >
              <UserAvatar userData={userData} height={"2.2rem"} width={"2.2rem"} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 4,
              sx: {
                mt: 1.5,
                minWidth: 200,
              },
            }}
          >
            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/profile") }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: "1rem" }}>
                <UserAvatar userData={userData} height={"2.2rem"} width={"2.2rem"} />
                <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 500 }}>Profile</Typography>
              </Box>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleLogout}
            >
              <ListItemIcon>
                <FaSignOutAlt />
              </ListItemIcon>
              <Typography variant="body1">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={notifyDrawer}
        onClose={toggleNotifyDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "360px",
            p: "1rem",
            borderRadius: "8px 0 0 8px",
          },
        }}
      >
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="1rem">
          <Typography variant="h6" fontWeight="600">
            Notifications
          </Typography>
          <IconButton
            onClick={toggleNotifyDrawer(false)}
          >
            <CloseRounded />
          </IconButton>
        </Box>

        {profileNotify ?
          < Box
            sx={{
              borderRadius: "12px",
              p: "1rem",
              mb: "1rem",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
              cursor: "pointer"
            }}
            onClick={() => navigate("/dashboard/profile")}
          >
            <Avatar
              sx={{
                backgroundColor: "#4f46e5",
                width: "48px",
                height: "48px",
              }}
            >
              <PersonRounded />
            </Avatar>
            <Box>
              <Typography fontWeight="500" fontSize="1rem">
                Complete your Profile
              </Typography>
              <Typography fontSize="0.875rem" color="#9ca3af">
                for better experience
              </Typography>
            </Box>
            <Box>
              <ProfileCircularProgress value={profileCompleted} />
            </Box>
          </Box> : <Typography textAlign="center" fontSize="0.875rem" color="#9ca3af">
            No more notifications
          </Typography>
        }
      </Drawer >
    </Box >
  );
};

export default Navbar;