import { Box, createTheme, MenuItem, Select } from "@mui/material";
import usaFlag from "../assets/Flags/usaFlag.webp";
import indiaFlag from "../assets/Flags/indiaFlag.png";
const styles = {
  custom_container: "w-11/12 hidden sm:block",
  heading:
    "text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]",
  section: "w-11/12 mx-auto",
  productTitle: "text-[25px] font-[600] font-Roboto text-[#333]",
  productDiscountPrice: "font-bold text-[18px] text-[#333] font-Roboto",
  price: "font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through",
  shop_name: "pt-3 text-[15px] text-blue-400 pb-3",
  active_indicator: "absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
  button:
    "w-[150px] bg-black h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer",
  cart_button:
    "px-[20px] h-[38px] rounded-[20px] bg-[#f63b60] flex items-center justify-center cursor-pointer",
  cart_button_text: "text-[#fff] text-[16px] font-[600]",
  input: "w-full border p-1 rounded-[5px]",
  activeStatus:
    "w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
  noramlFlex: "flex items-center",
  border_button:
    "border-2 border-[#3957db] text-[#3957db] px-4 py-2 rounded-lg shadow",
  title: "text-[1.2rem] font-[500] font-Roboto text-[#333]",
};
// #3957db blue
export default styles;

export const pageScrollStyle = {
  "&::-webkit-scrollbar": { width: "0.4rem" },
  "&::-webkit-scrollbar-track": {
    border: "1px solid #e5e5e5",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#00B3B0",
    borderRadius: "10px",
  },
};

export const lebaleStyle = {
  fontSize: "0.9rem",
  fontWeight: 500,
};

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#00B3B0", // Primary brand color
      light: "#66E0DD", // Lighter shade of primary for hover/disable
      contrastText: "#FFFFFF", // Text color on primary
    },
    secondary: {
      main: "#db3a3e", // Secondary brand color
      light: "#FF6F61", // Lighter shade of secondary
      dark: "#A5001E", // Darker shade of secondary
      contrastText: "#FFFFFF", // Text color on secondary
    },
    text: {
      primary: "#1C1B1F", // Main text color
      secondary: "#888", // Secondary text color
      disabled: "#CCCCCC", // Disabled text color
    },
    success: {
      main: "#198754", // Green for success
      light: "#28A745", // Lighter green
      dark: "#14532D", // Darker green
      contrastText: "#FFFFFF", // Text on success
    },
    danger: {
      main: "#dc3545", // Red for error
      light: "#FF5C6E", // Lighter red
      dark: "#A40020", // Darker red
      contrastText: "#FFFFFF", // Text on danger
    },
    warning: {
      main: "#ffc107", // Mustard yellow
      light: "#FFD54F", // Lighter yellow
      dark: "#B37F00", // Darker yellow
      contrastText: "#1C1B1F", // Text on warning
    },
    info: {
      main: "#0dcaf0", // Indigo for information
      light: "#5BDFF8", // Lighter blue
      dark: "#007B9C", // Darker blue
      contrastText: "#FFFFFF", // Text on info
    },
    light: {
      main: "#f8f9fa", // Light gray
      contrastText: "#1C1B1F", // Text on light backgrounds
    },
    black: {
      main: "#000000", // Black
      contrastText: "#FFFFFF", // Text on black
    },
    white: {
      main: "#FFFFFF", // White
      contrastText: "#1C1B1F", // Text on white
    },
    background: {
      default: "#F9F9F9", // Default background color
      paper: "#FFFFFF", // Paper background
    },
    divider: "#E0E0E0", // Divider lines
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          borderRadius: "0.4rem",
          textTransform: "capitalize",
          whiteSpace: "nowrap",
        },
        containedPrimary: {
          color: "#FFFFFF",
        },
        containedSecondary: {
          color: "#FFFFFF",
        },
        outlinedPrimary: {
          border: "1px solid #00B3B0",
          color: "#00B3B0",
        },
        outlinedSecondary: {
          border: "1px solid #db3a3e",
          color: "#db3a3e",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: "0.5rem",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
});

// // FORMAT NUMBERS WITH COMMAS LIKE (27,444)
// export const formatNumberWithCommas = (number) => {
//   return number.toLocaleString();
// };

// // PRICE FUNCTION
// export const MrPrice = (Price) => {
//   return Price?.toLocaleString("en-US", {
//     style: "currency",
//     currency: "INR",
//   });
// };

// // GREETING FUNCTION
// export const getGreeting = () => {
//   const currentTime = new Date().toLocaleTimeString("en-US", {
//     timeZone: "Asia/Kolkata",
//   });
//   const hour = parseInt(currentTime.split(":")[0]);

//   if (hour >= 4 && hour < 12) {
//     return "Good morning!";
//   } else if (hour >= 12 && hour < 17) {
//     return "Good afternoon!";
//   } else {
//     return "Good evening!";
//   }
// };

// // USER STATUS (2 MONTH'S AGO) FUNCTION
// export const timeAgo = (timestamp) => {
//   const secondsAgo = Math.floor((Date.now() - timestamp) / 1000);

//   const intervals = {
//     year: 31536000,
//     month: 2592000,
//     day: 86400,
//     hour: 3600,
//     minute: 60,
//   };

//   for (let interval in intervals) {
//     const value = Math.floor(secondsAgo / intervals[interval]);
//     if (value >= 1) {
//       return value + " " + (value === 1 ? interval : interval + "s") + " ago";
//     }
//   }

//   return "Just now";
// };

// // date formate log date
export const formattedDate = (date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};
export const formatteDateDay = (date) => {
  return new Date(date).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

// //phonenumber formatt
// export const formatPhoneNumber = (phoneNumber) => {
//   const numericOnly = phoneNumber.replace(/\D/g, "");
//   const formattedNumber = `+${numericOnly.slice(0, 2)} ${numericOnly.slice(
//     2,
//     7
//   )} ${numericOnly.slice(7)}`;

//   return formattedNumber;
// };

// // Get First character of each word
export const getFirstCharacters = (name) => {
  if (!name) return null; // Handling null or undefined cases
  return name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("")
    .toUpperCase();
};

export const calculatePresent = (totalnum, num) => {
  return Math.round((num / totalnum) * 100);
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// Utility function for debounce
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const contry_option = [
  { flag: indiaFlag, code: "+91" },
  { flag: usaFlag, code: "+1" },
];

export const CountrySelect = ({ value, onChange }) => (
  <Select
    autoWidth
    size="small"
    value={value}
    onChange={(e) => onChange(e.target.value)}
  >
    {contry_option?.map((country, index) => (
      <MenuItem key={index} value={country.code}>
        <Box
          sx={{
            height: "1.5rem",
            width: "2rem",
            borderRadius: "0.2rem",
            overflow: "hidden",
          }}
        >
          <img
            src={country.flag}
            alt={`Flag ${index}`}
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
      </MenuItem>
    ))}
  </Select>
);
