import React, { Suspense, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import SuspanceLoading from "./SuspanceLoading";
import Header from "./Homepage/Header";
import { useLocation } from "react-router-dom";
import SideNavBar from "./LawyerCompo/Dashboard/SideNavBar";
import Navbar from "./LawyerCompo/Dashboard/Navbar";
import Footer from "./Homepage/Footer";

const Layout = ({ children }) => {
  const location = useLocation();
  const [renderContent, setRenderContent] = useState(null);

  const xs = useMediaQuery("(max-width: 600px)");
  const sm = useMediaQuery("(min-width: 601px) and (max-width: 900px)");

  const urlPath = location.pathname;

  const renderLayout = () => {
    // 1. Homepage/Landing Layout
    if (urlPath === "/") {
      return (
        <Box
          sx={{
            overflowX: "hidden",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header />
          <Box>{children}</Box>
          <Footer />
        </Box>
      );
    }
    // 2. Client web Layout
    else if (urlPath.startsWith("/client")) {
      return (
        <Box sx={{ display: "flex", overflowX: "hidden", minHeight: "100vh" }}>
          <SideNavBar />
          <Box sx={{ width: "100%", flexGrow: 1 }}>
            <Navbar />
            <Box sx={{ padding: xs ? "10px" : "20px" }}>{children}</Box>
          </Box>
        </Box>
      );
    }
    // 3. Lawyer Web Layout
    else if (urlPath.startsWith("/dashboard")) {
      return (
        <Box sx={{ width: "100%", overflowX: "hidden", minHeight: "100vh" }}>
          <Navbar />
          <Box
            sx={{ display: "flex", width: "100%", transition: "all 0.3s ease" }}
          >
            <SideNavBar />
            <Box
              sx={{
                p: xs ? "0.5rem" : "1rem",
                width: "100%",
                overflowX: "hidden",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ overflowX: "hidden", minHeight: "100vh" }}>{children}</Box>
    );
  };

  useEffect(() => {
    setRenderContent(renderLayout());
  }, [urlPath, children]);

  return <Suspense fallback={<SuspanceLoading />}>{renderContent}</Suspense>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
