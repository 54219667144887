import React, { useEffect, useState, useMemo } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { routes } from "../Routes";
import NotFoundPage from "../pages/NotFoundPage";
import SuspanceLoading from "./SuspanceLoading";
import SignupPage from "../pages/SignupPage";
import HomePage from "../pages/LandingPages/HomePage";
import ResetPassForm from "./Auth/ResetPassForm";
import NewUserPage from "../pages/LawyerPages/TeamMembers/NewUserPage";

const ProtectedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const isAuthenticated = localStorage.getItem("persist:root");
  const userId = localStorage.getItem("UserId");

  useEffect(() => {
    if (!isAuthenticated || !userId) {
      const publicPaths = [
        "/",
        "/login",
        "/request-forgot-password",
        "/request-forgot-password/verification-code",
        "/new-user",
      ];
      if (!publicPaths.includes(location.pathname)) {
        navigate("/login");
      }
    }
    setLoading(false);
  }, [navigate, isAuthenticated, userId, location]);

  const processedRoutes = useMemo(() => {
    return routes
      ?.filter((route) => !route.protected || isAuthenticated)
      ?.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ));
  }, []);

  if (loading) {
    return <SuspanceLoading />;
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<SignupPage />} />
      <Route
        path="/request-forgot-password/verification-code"
        element={<ResetPassForm />}
      />
      <Route path="/new-user" element={<NewUserPage />} />

      {processedRoutes}
      <Route path="*" element={<NotFoundPage />} />

      {!isAuthenticated && (
        <Route path="*" element={<Navigate to="/login" replace />} />
      )}
    </Routes>
  );
};

export default ProtectedRoutes;
